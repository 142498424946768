import React from 'react';
import PropTypes from 'prop-types';
import PhoneForm from './forms/reset-code/PhoneForm';
import EmailForm from './forms/reset-code/EmailForm';

const getComponent = props => {
  switch (props.user.type) {
    case 'EMAIL':
      return <EmailForm {...props} />;
    default:
      return <PhoneForm {...props} />;
  }
};

const ResetCodeComponent = props => getComponent(props);

ResetCodeComponent.propTypes = {
  handleSubmitCode: PropTypes.func.isRequired,
  handleResendCode: PropTypes.func.isRequired,
  handleCodeInputChange: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
};

export default ResetCodeComponent;
