import { ADD_TOAST } from 'constants/actionTypes';
import { SERVER_ERROR } from 'constants/messages';
import axios from 'utils/axios';

export const GO_TO_PAGE = 'GO_TO_PAGE';

export function goToPage(page) {
  return {
    type: GO_TO_PAGE,
    payload: page
  };
}

/**
 * Gets the authentication provider given the email
 *
 * @param {string} url The string of the url to be called
 * @param {string} data The body object with the user email
 */
export const getAuthProvider = (url, data) => dispatch =>
  axios
    .post(url, data)
    .then(response => response)
    .catch(request => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
      return request.response;
    });
