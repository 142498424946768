import { formatNumber } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

class BackButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleBackButton = this.handleBackButton.bind(this);
  }

  getPhoneFormat() {
    if (Object.keys(this.props.user.phoneData).length > 0) {
      return formatNumber(this.props.user.phoneData, 'International');
    }
    return '';
  }

  getEmailFormat() {
    return this.props.user.id;
  }

  handleBackButton() {
    this.props.backButton();
  }

  render() {
    return (
      <div className="back-button__wrapper">
        <i
          tabIndex="-1"
          role="button"
          className="icon-longArrowLeft back-button__icon"
          onClick={this.handleBackButton}
        />
        <h6 className="back-button__user_id">
          {this.props.user.type === 'PHONE' && this.getPhoneFormat()}
          {this.props.user.type === 'EMAIL' && this.getEmailFormat()}
        </h6>
      </div>
    );
  }
}

BackButton.propTypes = {
  user: PropTypes.object,
  backButton: PropTypes.func,
};

export default BackButton;
