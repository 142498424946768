import { Button, Input } from '@promptlylabs/skeleton';
import React from 'react';

const PhoneForm = props => (
  <form>
    <Input
      id="cCode"
      value={props.code}
      type="text"
      label={props.t('Code')}
      error={props.inputError}
      fullWidth
      helperText={props.inputError || undefined}
      onChange={e => props.handleCodeInputChange(e)}
    />
    <div className="prom-login-content__actions mg-top-50px flex">
      <a tabIndex="-1" role="link" onClick={e => props.handleResendCode(e)} id="resend-code">
        {props.t('Resend code')}
      </a>
      <Button onClick={e => props.handleSubmitCode(e)} color="primary" variant="contained">
        {props.t('Password reset')}
      </Button>
    </div>
  </form>
);
export default PhoneForm;
