import React from 'react';
import { PAGE_LOGIN } from '../../../pages';

const ResetHeader = props => (
  <div>
    <h1 className="prom-login-content__title">{props.title}</h1>
    <p>
      {props.description}{' '}
      <a onClick={() => props.goToPage(PAGE_LOGIN)} role="link" tabIndex="-1">
        {props.t('login page')}
      </a>
      .
    </p>
  </div>
);

export default ResetHeader;
