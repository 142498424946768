import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';

const EmailFormComponent = props => (
  <a tabIndex="-1" role="link" onClick={e => props.handleResendCode(e, props.context)}>
    {props.t('Resend email')}
  </a>
);

EmailFormComponent.propTypes = {
  handleResendCode: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

export default withNamespaces()(EmailFormComponent);
