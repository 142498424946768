import { Button, Input } from '@promptlylabs/skeleton';
import React from 'react';
import { fullNumber, validateEmail, validatePhone } from 'utils/validations';
import PhoneCountry from '../../../components/PhoneCountry';

class ResetFormComponent extends React.Component {
  state = {
    value: this.props.user.phoneData.phone || this.props.user.id || '',
    inputError: undefined,
    submitting: false
  };

  getTitleMessage = isPhone => {
    if (isPhone) {
      return this.props.t('We will send you an SMS with a reset code. Use it to reset your password.');
    }
    return this.props.t('We will send you an email with a reset link. Use it to reset your password');
  };

  getErrorMessage = isPhone => (isPhone ? 'phone' : 'email');

  getBtnMessage = isPhone => {
    if (isPhone) {
      return this.props.t('Send reset SMS');
    }
    return this.props.t('Send reset email');
  };

  isPhoneValid = (value, country) => {
    let isValid;

    [isValid] = validatePhone(value);
    if (!isValid) {
      [isValid] = validatePhone(value, country);
    }
    return isValid;
  };

  isUserIdValid = (value, country) => {
    if (this.isPhone()) {
      if (!this.isPhoneValid(value, country)) {
        this.setState({ inputError: this.props.t('Invalid phone number for the selected country') });
        return false;
      }
    } else if (value === '') {
      this.setState({ inputError: this.props.t('Empty values are not allowed') });
      return false;
    } else if (validateEmail(value) === false) {
      this.setState({ inputError: this.props.t('Please insert a valid email') });
      return false;
    }

    return true;
  };

  handleNextButtonClick = event => {
    event.preventDefault();
    if (!this.state.submitting) {
      this.setState({ submitting: true }, () => {
        // check if user id is valid
        if (!this.isUserIdValid(this.state.value, this.props.user.phoneData.country)) {
          return;
        }

        let { value } = this.state;

        if (this.isPhone()) {
          value = fullNumber({ phone: value, country: this.props.user.phoneData.country });
        }

        this.props.handleAccountIdChange(value);
        this.props.handleFormSubmit(value).then(() => {
          this.setState({ submitting: false });
        });
      });
    }
  };

  handleInputChange = event => {
    this.setState({ value: event.target.value, inputError: undefined });
  };

  handleChangeCountry = value => {
    this.setState({ inputError: undefined });
    if (this.props.handleChangeCountry) {
      this.props.handleChangeCountry(value);
    }
  };

  isPhone = () => this.props.type === 'PHONE';

  render() {
    return (
      <div>
        <p>{this.getTitleMessage(this.isPhone())}</p>
        {/* {this.props.errorMessage && (
          <p className="error mg-top-0px mg-bottom-10px">{this.getErrorMessage(this.isPhone())}</p>
        )} */}
        {this.props.errorMessage && <p className="error mg-top-0px mg-bottom-10px">{this.props.errorMessage}</p>}
        <form onSubmit={this.handleNextButtonClick}>
          {this.isPhone() && (
            <PhoneCountry
              t={this.props.t}
              value={this.props.value}
              label={this.props.t('Phone number')}
              fullWidth
              onChange={(event, value) => this.handleChangeCountry(value)}
            />
          )}
          <Input
            id="user-id"
            placeholder={this.isPhone() ? this.props.t('Your phone number') : this.props.t('Your email')}
            type={this.isPhone() ? 'text' : 'email'}
            label={this.isPhone() ? undefined : this.props.t('Email')}
            error={this.state.inputError}
            fullWidth
            value={this.state.value}
            helperText={this.state.inputError}
            onChange={evt => this.handleInputChange(evt)}
          />

          <div className="prom-login-content__actions mg-top-50px flex">
            <a
              type="button"
              onClick={e => {
                e.preventDefault();
                this.props.handleBackButton();
              }}
              role="button"
              tabIndex="-1"
              id="back-button"
            >
              {this.props.t('Back')}
            </a>

            <Button onClick={this.handleNextButtonClick} color="primary" variant="contained">
              {this.getBtnMessage(this.isPhone())}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default ResetFormComponent;
