import 'assets/css/login-layout.scss';
import FooterLogin from 'components/FooterLogin';
import { GRAVIDITY } from 'constants/projectsEnvironments';
import ToastsProvider from 'containers/Toasts/ToastsProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import ContainerAuth from '../../components/ContainerAuth/index';
import FlyerAuth from '../../components/FlyerAuth/index';
import PageSlider from './components/PageSlider';
import LoginForm from './containers/LoginForm';
import NewPasswordForm from './containers/NewPasswordForm';
import PasswordForm from './containers/PasswordForm';
import PasswordResetForm from './containers/PasswordResetForm';
import ResetCodeForm from './containers/ResetCodeForm';
import { PAGE_LOGIN, PAGE_NEW_PASSWORD, PAGE_PASSWORD, PAGE_PASSWORD_RESET, PAGE_PATH, PAGE_RESET_CODE } from './pages';
import './styles/login.scss';

export class LoginView extends React.Component {
  state = {
    user: {
      id: '',
      type: undefined,
      phoneData: {}
    },
    token: undefined,
    code: undefined,
    direction: undefined,
    prevPage: PAGE_LOGIN,
    page: PAGE_LOGIN,
    pageIndex: PAGE_PATH[PAGE_LOGIN]
  };

  componentDidMount() {
    if (window.PROJECT === GRAVIDITY) {
      document.querySelector('body').classList.add('body-gravidity');
    }
  }

  goToPage = newPage => {
    const currentPageIndex = this.state.pageIndex;
    const newPageIndex = PAGE_PATH[newPage] || 0;
    const direction = currentPageIndex > newPageIndex ? 'RIGHT' : 'LEFT';
    this.setState({
      prevPage: this.state.page,
      page: newPage,
      direction,
      pageIndex: newPageIndex
    });
  };

  handleChangeCountry = countryCode => {
    const newState = { ...this.state };
    newState.user.phoneData.country = countryCode.code;
    this.setState(newState);
  };

  handleAccountIdChange = value => {
    const newState = { ...this.state };
    newState.user.id = value;
    this.setState(newState);
  };

  handleUserIdSubmit = (event, obj) => {
    const newState = { ...this.state };
    newState.user.id = obj.value;
    newState.user.type = obj.type;
    newState.user.phoneData = obj.phoneData;
    this.setState(newState, () => this.goToPage(obj.nextPage));
  };

  handleSetCode = code => {
    this.setState({ code });
  };

  handleSetToken = token => {
    this.setState({ token });
  };

  isVisible = page => this.state.page === page;

  render() {
    return (
      <ToastsProvider>
        <section className="login-view-container">
          <FlyerAuth t={this.props.t} />
          <ContainerAuth t={this.props.t}>
            <div className="prom-login-content">
              <PageSlider
                index={0}
                pageIndex={this.state.pageIndex}
                direction={this.state.direction}
                show={this.isVisible(PAGE_LOGIN)}
              >
                <LoginForm user={this.state.user} handleClick={this.handleUserIdSubmit} t={this.props.t} />
              </PageSlider>

              <PageSlider
                index={1}
                pageIndex={this.state.pageIndex}
                direction={this.state.direction}
                show={this.isVisible(PAGE_PASSWORD)}
              >
                <PasswordForm
                  handleChangeCountry={this.handleChangeCountry}
                  user={this.state.user}
                  goToPage={this.goToPage}
                  t={this.props.t}
                />
              </PageSlider>

              <PageSlider
                index={2}
                pageIndex={this.state.pageIndex}
                direction={this.state.direction}
                show={this.isVisible(PAGE_PASSWORD_RESET)}
              >
                <PasswordResetForm
                  handleChangeCountry={this.handleChangeCountry}
                  handleAccountIdChange={this.handleAccountIdChange}
                  user={this.state.user}
                  goToPage={this.goToPage}
                  prevPage={this.state.prevPage}
                  t={this.props.t}
                />
              </PageSlider>

              <PageSlider
                index={3}
                pageIndex={this.state.pageIndex}
                direction={this.state.direction}
                show={this.isVisible(PAGE_RESET_CODE)}
              >
                <ResetCodeForm
                  user={this.state.user}
                  goToPage={this.goToPage}
                  handleSetCode={this.handleSetCode}
                  handleSetToken={this.handleSetToken}
                  t={this.props.t}
                />
              </PageSlider>

              <PageSlider
                index={4}
                pageIndex={this.state.pageIndex}
                direction={this.state.direction}
                show={this.isVisible(PAGE_NEW_PASSWORD)}
              >
                <NewPasswordForm
                  user={this.state.user}
                  goToPage={this.goToPage}
                  code={this.state.code}
                  token={this.state.token}
                  t={this.props.t}
                />
              </PageSlider>
            </div>
            {!this.props.noFooter && <FooterLogin t={this.props.t} language={this.props.language} />}
          </ContainerAuth>
        </section>
      </ToastsProvider>
    );
  }
}

LoginView.propTypes = {
  noFooter: PropTypes.bool
};

LoginView.defaultProps = {
  noFooter: false
};

export default withNamespaces()(LoginView);
