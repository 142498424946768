import { RESET_URL } from 'constants/urls';
import ToastContext from 'contexts/ToastsContext';
import PropTypes from 'prop-types';
import React from 'react';
import fetchApi from 'utils/fetchApi';
import ResetHeader from '../components/forms/reset-code/ResetHeader';
import ResetCodeComponent from '../components/ResetCodeComponent';
import { PAGE_LOGIN, PAGE_NEW_PASSWORD } from '../pages';

class ResetCodeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: undefined,
      inputError: undefined,
      nextPage: PAGE_NEW_PASSWORD,
      prevPage: PAGE_LOGIN,
    };

    this.handleNextButton = this.handleNextButton.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
  }

  getDescription = (type, trans = this.props.t) => {
    switch (type) {
      case 'EMAIL':
        return trans('We have sent you an email with a reset link. Use it to reset your password, or go back to');
      default:
        return trans(
          'We have sent you an SMS with a reset code which will be valid for the next 2 minutes. Use it to reset your password, or go back to'
        );
    }
  };

  handleCodeInputChange(event) {
    this.setState({
      code: event.target.value,
      inputError: undefined,
    });
  }

  handleSubmitCode(event, context) {
    event.preventDefault();

    if (this.state.code === undefined || this.state.code === '') {
      this.setState({ inputError: this.props.t('Invalid code') });
      return;
    }

    const successFunc = (data, context) => {
      // add context message
      this.props.handleSetToken(data.token);
      this.props.handleSetCode(this.state.code);
      this.handleNextButton();
    };

    const serverErrorsFunc = data => {
      const message = data.errors ? data.errors[0] : this.props.t('Invalid Token');
      this.setState({ inputError: message });
    };

    const errorFunc = (data = undefined) => {
      this.setState({ serverRequest: false });
    };

    fetchApi({
      url: `/code/${this.state.code}`,
      method: 'GET',
      context,
      successFunc,
      contentType: 'application/json',
      requestWith: true,
      serverErrorsFunc,
      errorFunc,
    });
  }

  handleResendCode(event, context) {
    event.preventDefault();
    const type = this.props.user.type.toLowerCase();

    const formBody = [`${encodeURIComponent(type)}=${encodeURIComponent(this.props.user.id)}`].join('&');

    const successFunc = (data, context) => {
      // add context message
      const message = `${type === 'email' ? this.props.t('Email') : this.props.t('Code')} ${this.props.t(
        'resent successfully'
      )}!`;
      context.addToast({
        message: this.props.t(message),
        type: 'success',
      });
    };

    const serverErrorsFunc = data => {
      this.setState({ loginError: true, serverRequest: false });
    };

    const errorFunc = (data = undefined) => {
      this.setState({ serverRequest: false });
    };

    fetchApi({
      url: RESET_URL,
      formBody,
      method: 'POST',
      context,
      successFunc,
      requestWith: true,
      serverErrorsFunc,
      errorFunc,
    });
  }

  handleNextButton() {
    this.props.goToPage(this.state.nextPage);
  }

  handleBackButton() {
    this.props.goToPage(this.state.prevPage);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.show && (
          <ToastContext.Consumer>
            {context => (
              <section>
                <ResetHeader
                  title={this.props.t('Password reset')}
                  description={this.getDescription(this.props.user.type)}
                  goToPage={this.props.goToPage}
                  t={this.props.t}
                />

                <div className="prom-login-forms-wrapper">
                  <ResetCodeComponent
                    code={this.state.code}
                    inputError={this.state.inputError}
                    value={this.props.user.phoneData ? this.props.user.phoneData.country : undefined}
                    user={this.props.user}
                    goToPage={this.props.goToPage}
                    handleNextButton={this.handleNextButton}
                    handleBackButton={this.handleBackButton}
                    handleResendCode={e => this.handleResendCode(e, context)}
                    handleSetCode={() => this.handleSetCode(this.state.code)}
                    handleCodeInputChange={e => this.handleCodeInputChange(e)}
                    handleSubmitCode={e => this.handleSubmitCode(e, context)}
                    context={context}
                    t={this.props.t}
                  />
                </div>
              </section>
            )}
          </ToastContext.Consumer>
        )}
      </React.Fragment>
    );
  }
}

ResetCodeForm.propTypes = {
  user: PropTypes.object.isRequired,
  goToPage: PropTypes.func.isRequired,
  handleSetCode: PropTypes.func.isRequired,
  handleSetToken: PropTypes.func.isRequired,
};

export default ResetCodeForm;
