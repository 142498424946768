import { theme as skeletonDefaultTheme, ThemeProvider as SkeletonThemeProvider } from '@promptlylabs/skeleton';
import { updateUserData } from 'actions/user';
import 'assets/css/base.css';
import 'babel-polyfill';
import ErrorBoundary from 'components/ErrorBoundary';
import NetworkAwareContainer from 'components/NetworkAwareContainer';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { APP_URL } from 'constants/urls';
import { createBrowserHistory } from 'history';
import { get } from 'lodash';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { connect, Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import createSentryMiddleware from 'redux-sentry-middleware';
import thunk from 'redux-thunk';
import { getUserUrl } from 'selectors/_selected_api';
import { getSelectedInstBrandColor, getUserInstitution } from 'selectors/selected_institutions';
import { ThemeProvider } from 'styled-components';
import 'url-search-params-polyfill';
import { pSBC } from 'utils/colors';
import getTheme from 'utils/theme';
import { FeatureFlagProvider } from 'providers/FeatureFlagContext';
import HelpScout from '../../../internals/helpscout';
import i18next from '../../../internals/i18n';
import { healthSpaceDomainURL, translations } from '../../../internals/middleware';
import mixpanelMiddleware from '../../../internals/middleware/mixpanel';
import ScrollToTop from '../../components/ScrollToTop/index';
import { getUser } from '../../selectors/selected_user';
import gb from '../../../internals/growthbook';
import Sentry from '../../../internals/sentry';

let store = {};
let timer;

const withBundle = (Component, PromptlyTheme, reducers, basename = APP_URL) => {
  const history = createBrowserHistory({
    basename
  });

  store = createStore(
    reducers(history),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(
      thunk,
      translations,
      healthSpaceDomainURL,
      routerMiddleware(history),
      mixpanelMiddleware,
      reduxPackMiddleware
    ),
    createSentryMiddleware(window.Sentry, {
      // Optionally pass some options here.
    })
  );

  const setTriggers = (url, fn, user = {}) => {
    const email = user ? user.email : undefined;

    // When we dont have user email we use helpscout form to store in promptly
    function setEventListener(method) {
      window.Beacon('on', method, data => {
        HelpScout.process({
          func: fn,
          url,
          email,
          newEmail: data.email
        });
      });
    }
    setEventListener('email-sent');
    setEventListener('chat-started');
  };

  const BundleHOC = props => {
    const isHelpscoutEnabled = window.HELP_SCOUT_ENABLED;

    useEffect(() => {
      async function fetchData() {
        const response = await fetch(
          'https://growthbook-proxy.prod.labs.promptly.health/api/features/sdk-o3xjzPPuB8aeCyF'
        );
        Sentry.captureMessage(
          `Growthbook Test Request: ${response.ok ? 'Request ok' : 'Something went wrong'} ${response.status}`
        );
      }
      fetchData();
    }, []);
    /**
     * When user or selected institution changes checks if needs to configure new beacon
     */
    useEffect(() => {
      if (props.user && props.selectedInstitution.id && isHelpscoutEnabled) {
        if (timer) {
          window.clearInterval(timer);
        }
        const { mappings, signatures } = props.user.helpscout;
        timer = setTimeout(() => {
          HelpScout.logout();
          HelpScout.init(mappings, get(props.selectedInstitution, 'id'), props.user.role);
          HelpScout.identify({
            user: props.user,
            role: props.user.role,
            institution: get(props.selectedInstitution, 'id'),
            signatures
          });
        }, 0);
      }
    }, [props.selectedInstitution.id]);

    useEffect(() => {
      if (props.user && window.userGuiding) {
        window.userGuiding.identify(props.user.uuid);
      }
      if (props.user && window.GROWTHBOOK_API_HOST && window.GROWTHBOOK_CLIENT_KEY) {
        gb.setAttributes({
          env: window.location.host,
          uid: props.user.uuid
        });
      }
    }, [props.user]);

    // based in a primary color we set institution branding
    const defaultTheme = getMuiTheme(PromptlyTheme);
    const theme = getTheme(defaultTheme, {
      palette: {
        primary: props.color || defaultTheme.palette.primary,
        primary900: props.color ? pSBC(props.color, -10) : defaultTheme.palette.primary900,
        primary600: props.color ? pSBC(props.color, 0) : defaultTheme.palette.primary600,
        primary500: props.color ? pSBC(props.color, 10) : defaultTheme.palette.primary
      }
    });

    const skeletonTheme = {
      ...skeletonDefaultTheme,
      palette: {
        ...skeletonDefaultTheme.palette,
        primary: {
          ...skeletonDefaultTheme.palette.primary,
          main: props.color || skeletonDefaultTheme.palette.primary.main,
          light: props.color ? pSBC(props.color, +10) : skeletonDefaultTheme.palette.primary.light,
          dark: props.color ? pSBC(props.color, -10) : skeletonDefaultTheme.palette.primary.dark
        }
      }
    };

    if (isHelpscoutEnabled) {
      setTriggers(props.userURL, props.updateUserData, props.user);
    }

    return (
      <SkeletonThemeProvider theme={skeletonTheme}>
        <MuiThemeProvider muiTheme={theme}>
          <I18nextProvider i18n={i18next}>
            <ErrorBoundary>
              <ConnectedRouter history={history}>
                <NetworkAwareContainer>
                  <Router history={history}>
                    <ScrollToTop />
                    <ThemeProvider theme={theme}>
                      <Component />
                    </ThemeProvider>
                  </Router>
                </NetworkAwareContainer>
              </ConnectedRouter>
            </ErrorBoundary>
          </I18nextProvider>
        </MuiThemeProvider>
      </SkeletonThemeProvider>
    );
  };

  BundleHOC.defaultProps = {
    color: undefined,
    user: undefined,
    selectedInstitution: undefined
  };

  BundleHOC.propTypes = {
    color: PropTypes.string,
    user: PropTypes.object,
    userURL: PropTypes.string.isRequired,
    updateUserData: PropTypes.func.isRequired,
    selectedInstitution: PropTypes.object
  };

  const mapStateToProps = state => ({
    selectedInstitution: getUserInstitution(state),
    color: getSelectedInstBrandColor(state),
    user: getUser(state),
    userURL: getUserUrl(state)
  });

  const ComposedHoc = connect(mapStateToProps, { updateUserData })(BundleHOC);

  return (
    <Provider store={store}>
      <FeatureFlagProvider provider={gb}>
        <ComposedHoc />
      </FeatureFlagProvider>
    </Provider>
  );
};

export default withBundle;
