import { RESET_URL } from 'constants/urls';
import ToastContext from 'contexts/ToastsContext';
import PropTypes from 'prop-types';
import React from 'react';
import fetchApi from 'utils/fetchApi';
import ResetFormComponent from '../components/ResetFormComponent';
import { PAGE_PASSWORD, PAGE_RESET_CODE } from '../pages';

export class PasswordResetForm extends React.Component {
  state = {
    errorMessage: undefined,
  };

  handleNextButton = () => {
    this.setState({ errorMessage: undefined });
    this.props.goToPage(this.props.nextPage);
  };

  handleBackButton = () => {
    this.setState({ errorMessage: undefined });
    this.props.goToPage(this.props.prevPage);
  };

  handleChangeCountry = value => {
    this.setState({ errorMessage: undefined });
    this.props.handleChangeCountry(value);
  };

  buildData = (userID, type = 'email') => {
    const typeLower = type.toLowerCase();
    return [`${encodeURIComponent(typeLower)}=${encodeURIComponent(userID)}`].join('&');
  };

  handleFormSubmit = (userID, context) => {
    const formBody = this.buildData(userID, this.props.user.type);

    const successFunc = data => {
      if (data.location) {
        window.location = data.location;
        return;
      }
      this.handleNextButton();
    };

    const serverErrorsFunc = data => {
      const { errors } = this.props.user.type === 'EMAIL' ? data.form.fields.email : data.form.fields.phone;
      this.setState({ errorMessage: [errors] });
      context.addToast({
        message: this.props.t('Oops, something went wrong!'),
        type: 'error',
      });
    };

    const errorFunc = () => {};

    return fetchApi({
      url: RESET_URL,
      formBody,
      method: 'POST',
      context,
      successFunc,
      requestWith: true,
      serverErrorsFunc,
      errorFunc,
    });
  };

  render() {
    return (
      <ToastContext.Consumer>
        {context => (
          <div className="prom-login-forms-wrapper">
            <h1 className="prom-login-content__title">{this.props.t('Password reset')}</h1>
            {this.props.show && (
              <ResetFormComponent
                type={this.props.user.type}
                handleChangeCountry={this.handleChangeCountry}
                value={this.props.user.phoneData ? this.props.user.phoneData.country : undefined}
                user={this.props.user}
                goToPage={this.props.goToPage}
                handleNextButton={this.handleNextButton}
                handleBackButton={this.handleBackButton}
                context={context}
                handleFormSubmit={userID => this.handleFormSubmit(userID, context)}
                handleAccountIdChange={this.props.handleAccountIdChange}
                errorMessage={this.state.errorMessage}
                t={this.props.t}
              />
            )}
          </div>
        )}
      </ToastContext.Consumer>
    );
  }
}

PasswordResetForm.defaultProps = {
  show: true,
  nextPage: PAGE_RESET_CODE,
  prevPage: PAGE_PASSWORD,
};

PasswordResetForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleChangeCountry: PropTypes.func.isRequired,
  handleAccountIdChange: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  nextPage: PropTypes.string,
  prevPage: PropTypes.string,
};

export default PasswordResetForm;
