import PropTypes from 'prop-types';
import React from 'react';

class PageSlider extends React.Component {
  static defaultProps = {
    direction: 'LEFT',
  };

  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show ? this.props.show : false,
      direction: undefined,
    };

    this.translateValue = undefined;
  }

  componentDidUpdate() {
    if (!this.props.show && this.state.show) {
      if (this.props.direction !== this.state.direction) {
        // difine transition value based in direction. IF leave move to Left if enter move to Right
        this.setState({
          translateValue: this.props.direction === 'LEFT' ? '-120%' : '120%',
          direction: this.props.direction,
        });
      }
      setTimeout(() => {
        this.setState({ show: this.props.show });
      }, 1000);
    } else if (this.props.show && !this.state.show) {
      this.setState({
        show: this.props.show,
        translateValue: this.props.direction === 'LEFT' ? '-120%' : '120%',
        direction: this.props.direction,
      });
    }
  }

  renderChildren() {
    const cp = React.Children.map(this.props.children, child => {
      const props = {
        show: this.state.show,
      };
      return React.cloneElement(child, props);
    });
    return cp;
  }

  render() {
    let translateCss;

    if (this.props.show) {
      translateCss = 'translateX(0)';
    } else if (this.props.pageIndex < this.props.index) {
      translateCss = 'translateX(120%)';
    } else {
      translateCss = `translateX(${this.state.translateValue || '120%'})`;
    }

    const styleWrapper = {
      transition: 'transform 0.4s ease-in-out',
      transform: translateCss,
      width: '100%',
      position: 'absolute',
      opacity: this.state.show ? '1' : '0',
    };

    return <div style={styleWrapper}>{this.renderChildren()}</div>;
  }
}

PageSlider.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  direction: PropTypes.string,
  index: PropTypes.number.isRequired,
};

export default PageSlider;
