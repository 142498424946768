import { Button } from '@promptlylabs/skeleton';
import { PasswordChecker } from '@promptlylabs/ui-kit';
import { PASSWORD_CHECK } from 'constants/messages';
import { APP_URL } from 'constants/urls';
import ToastContext from 'contexts/ToastsContext';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import buildPasswordTrans from 'utils/buildPasswordTrans';
import fetchApi from 'utils/fetchApi';
import PasswordInput from '../../../components/Password/PasswordInput';

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export class NewPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      errors: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.setState({ password: e.target.value, errors: {} });
  }

  isPasswordValid(password) {
    if (password === '') {
      const errors = { password: this.props.t('Empty values are not allowed') };
      this.setState({ errors });
      return false;
    }
    return true;
  }

  handleSubmit(event, context) {
    event.preventDefault();
    const { password } = this.state;

    const successFunc = (data, context) => {
      window.location = APP_URL;
    };

    if (!this.isPasswordValid(password)) {
      return;
    }

    const serverErrorsFunc = data => {
      if (Object.keys(data).includes('password')) {
        this.setState({ errors: { password: data.password[0] } });
      }
    };

    const errorFunc = (data = undefined) => {};

    const data = { password, code: this.props.code };

    fetchApi({
      url: `/token/${this.props.token}`,
      method: 'POST',
      context,
      formBody: JSON.stringify(data),
      successFunc,
      contentType: 'application/json; charset=utf-8',
      requestWith: true,
      serverErrorsFunc,
      errorFunc
    });
  }

  render() {
    return (
      <ToastContext.Consumer>
        {context => (
          <React.Fragment>
            {this.props.show && (
              <div className="prom-login-forms-wrapper">
                <h1 className="prom-login-content__title">{this.props.t('New password')}</h1>
                <p className="mg-bottom-5px mg-top-20px">{this.props.t('Define a new password')}</p>
                <p>
                  <span style={{ fontWeight: '600' }}>{this.props.user.id}</span>
                </p>

                <form>
                  <PasswordInput
                    id="password"
                    label={this.props.t('Password')}
                    value={this.state.password}
                    error={this.state.errors.password}
                    helperText={this.state.errors.password}
                    onChange={e => this.handleInputChange(e)}
                  />
                  <PasswordChecker
                    {...buildPasswordTrans(PASSWORD_CHECK, this.props.t)}
                    password={this.state.password}
                    userID={this.props.user.id}
                  />
                  <ActionsWrapper className="prom-login-content__actions mg-top-50px flex">
                    <Button
                      onClick={event => {
                        this.handleSubmit(event, context);
                      }}
                      color="primary"
                      variant="contained"
                    >
                      {this.props.t('Save this password')}
                    </Button>
                  </ActionsWrapper>
                </form>
              </div>
            )}
          </React.Fragment>
        )}
      </ToastContext.Consumer>
    );
  }
}

NewPasswordForm.propTypes = {
  user: PropTypes.object.isRequired,
  code: PropTypes.string,
  token: PropTypes.string
};

export default NewPasswordForm;
