import { Button } from '@promptlylabs/skeleton';
import { KIOSK_COOKIE } from 'constants/app';
import { SIGNUP_URL } from 'constants/urls';
import PropTypes from 'prop-types';
import React from 'react';
import PasswordInput from '../../../components/Password/PasswordInput';
import PhoneCountry from '../../../components/PhoneCountry';

class PasswordFormComponent extends React.Component {
  state = {
    password: '',
    errorPassword: undefined,
    showSignUp: document.cookie.includes(KIOSK_COOKIE)
  };

  componentDidMount() {
    setTimeout(() => {
      document.querySelector('#password').focus();
    }, 500);
  }

  handlePasswordInputChange = event => {
    this.setState({
      password: event.target.value,
      errorPassword: undefined
    });
  };

  handleLoginClick = event => {
    event.preventDefault();

    if (this.state.password === '') {
      this.setState({ errorPassword: this.props.t('Please enter a password') });
      return;
    }
    this.setState({ errorPassword: undefined });
    this.props.onLoginSubmit(event, this.state.password, this.props.context);
  };

  handleChangeCountry = value => {
    if (this.props.handleChangeCountry) {
      this.props.handleChangeCountry(value);
    }
  };

  isPhone = () => this.props.type === 'PHONE';

  render() {
    return (
      <React.Fragment>
        {this.props.loginError && (
          <p data-cy="error-message" className="error mg-top-10px mg-bottom-0px">
            {this.props.loginError}
            {this.state.showSignUp && ` ${this.props.t('If you dont have a promptly account')} `}
            {this.state.showSignUp && <a href={SIGNUP_URL}>{this.props.t('get a new one')}</a>}
          </p>
        )}
        <div className="mg-top-30px">
          <form onSubmit={this.handleLoginClick}>
            {this.isPhone() && (
              <PhoneCountry
                t={this.props.t}
                fullWidth
                value={this.props.value}
                onChange={(event, value) => this.handleChangeCountry(value)}
                id="phone-country-input"
              />
            )}
            <div>
              <PasswordInput
                placeholder={this.props.t('Password')}
                label={this.props.t('Password')}
                error={this.state.errorPassword}
                helperText={this.state.errorPassword || undefined}
                onChange={evt => this.handlePasswordInputChange(evt)}
                value={this.state.password}
              />
            </div>
            <div className="prom-login-content__actions mg-top-50px flex">
              <a
                data-cy="forgot-password"
                role="link"
                tabIndex="-1"
                onClick={() => this.props.forgotPassword()}
                id="forgot-password"
              >
                {this.props.t('Forgot password?')}
              </a>
              <Button
                onClick={this.handleLoginClick}
                color="primary"
                variant="contained"
                disabled={this.props.serverRequest}
              >
                {this.props.t('Log in')}
              </Button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

PasswordFormComponent.propTypes = {
  value: PropTypes.string,
  onLoginSubmit: PropTypes.func,
  forgotPassword: PropTypes.func,
  handleChangeCountry: PropTypes.func,
  serverRequest: PropTypes.bool,
  onTogglePassword: PropTypes.func
};

export default PasswordFormComponent;
