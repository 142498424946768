export const PAGE_LOGIN = 'PAGE_LOGIN';
export const PAGE_PASSWORD = 'PAGE_PASSWORD';
export const PAGE_PASSWORD_RESET = 'PAGE_PASSWORD_RESET';
export const PAGE_RESET_CODE = 'PAGE_RESET_CODE';
export const PAGE_NEW_PASSWORD = 'PAGE_NEW_PASSWORD';

export const PAGE_PATH = {
  PAGE_LOGIN: 0,
  PAGE_PASSWORD: 1,
  PAGE_PASSWORD_RESET: 2,
  PAGE_RESET_CODE: 3,
  PAGE_NEW_PASSWORD: 4
};
