import { onClickLoginFormShowHidePassword, onClickLoginPasswordSubmit } from 'actions/datacollection';
import { LOGIN_URL } from 'constants/urls';
import ToastContext from 'contexts/ToastsContext';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getUrlParam } from 'utils/app';
import fetchApi from 'utils/fetchApi';
import { fullNumber } from 'utils/validations';
import BackButton from '../components/BackButton';
import PasswordFormComponent from '../components/PasswordFormComponent';
import { PAGE_LOGIN, PAGE_PASSWORD_RESET } from '../pages';

export class PasswordForm extends React.Component {
  static buildData(pwd, type, country, id, deviceUID) {
    return [
      `${encodeURIComponent('password')}=${encodeURIComponent(pwd)}`,
      `${encodeURIComponent('type')}=${encodeURIComponent(type)}`,
      `${encodeURIComponent('cCode')}=${encodeURIComponent(country)}`,
      `${encodeURIComponent('login')}=${encodeURIComponent(id)}`,
      `${encodeURIComponent('device_uid')}=${encodeURIComponent(deviceUID)}`
    ].join('&');
  }

  static defaultProps = {
    show: false
  };

  state = {
    serverRequest: false,
    loginError: undefined,
    prevPage: PAGE_LOGIN
  };

  static getUserID(type, userId, phoneData) {
    return type === 'EMAIL' ? userId : fullNumber(phoneData);
  }

  handleChangeCountry = value => {
    this.props.handleChangeCountry(value);
  };

  handleForgotPassword = () => {
    this.setState({ loginError: undefined });
    this.props.goToPage(PAGE_PASSWORD_RESET);
  };

  handleBackButton = () => {
    this.setState({ loginError: undefined });
    this.props.goToPage(this.state.prevPage);
  };

  handleSubmitLogin = (event, pwd, context) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onClickLoginPasswordSubmit();

    if (this.state.serverRequest) {
      return;
    }

    this.setState({ loginError: undefined, serverRequest: true });

    const id = PasswordForm.getUserID(this.props.user.type, this.props.user.id, this.props.user.phoneData);
    const deviceUID = getUrlParam('device_uid');
    const formBody = PasswordForm.buildData(
      pwd,
      this.props.user.type,
      this.props.user.phoneData.country,
      id,
      deviceUID
    );

    const successFunc = data => {
      sessionStorage.clear();
      window.location = data.location;
    };

    const serverErrorsFunc = data => {
      this.setState({ loginError: data.form.errors[0] || undefined, serverRequest: false });
    };

    const errorFunc = () => {
      this.setState({ serverRequest: false });
    };

    fetchApi({
      url: `${LOGIN_URL}${window.location.search}`,
      formBody,
      method: 'POST',
      context,
      successFunc,
      serverErrorsFunc,
      requestWith: true,
      errorFunc
    });
  };

  render() {
    return (
      <ToastContext.Consumer>
        {context => (
          <React.Fragment>
            {this.props.show && (
              <div className="prom-login-forms-wrapper">
                <h1 className="prom-login-content__title">{this.props.t('Your password')}</h1>
                <BackButton backButton={this.handleBackButton} user={this.props.user} />
                <PasswordFormComponent
                  type={this.props.user.type}
                  handleChangeCountry={this.handleChangeCountry}
                  value={this.props.user.phoneData ? this.props.user.phoneData.country : undefined}
                  forgotPassword={this.handleForgotPassword}
                  onLoginSubmit={this.handleSubmitLogin}
                  onTogglePassword={this.props.onClickLoginFormShowHidePassword}
                  loginError={this.state.loginError}
                  context={context}
                  t={this.props.t}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </ToastContext.Consumer>
    );
  }
}

PasswordForm.defaultProps = {
  onClickLoginFormShowHidePassword: () => {}
};

PasswordForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleChangeCountry: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onClickLoginPasswordSubmit: PropTypes.func.isRequired,
  onClickLoginFormShowHidePassword: PropTypes.func
};

const mapDispatchToProps = {
  onClickLoginPasswordSubmit,
  onClickLoginFormShowHidePassword
};

export default connect(null, mapDispatchToProps)(PasswordForm);
