export const DOCTOR = 'doctor';
export const NURSE = 'nurse';
export const SUPER_USER = 'superuser';
export const ADMIN = 'admin';
export const PATIENT = 'patient';
export const EPROM = 'eprom';
export const PROM = 'prom';
export const CRO = 'cro';

export const ROOT_APP = '/app/';

export const NO_SELECTION = 'no-selection';

export const KIOSK_COOKIE = 'KID';

export const PROMPTLY_FOOTER_HEIGHT = 80;

export const LOCATION_404 = '/404/';
export const INSURANCE = 'INSURANCE';

export const MAX_ACCEPTANCES_WIDTH = '960px';
export const MAX_ACCEPTANCES_CONTENT_WIDTH = '632px';
export const MOBILE_WIDTH = 768;
export const MOBILE_WIDTH_PX = `${MOBILE_WIDTH}px`;

export const ACCEPTANCE_TYPES = {
  USER_AGREEMENT: 'USER_AGREEMENT',
  CLINICAL_TRIAL: 'CLINICAL_TRIAL',
  CONTEXT_SCREEN: 'CONTEXT_SCREEN'
};
